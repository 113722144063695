import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { Waypoint } from 'react-waypoint'

import { PossibilitiesProps } from '@/components/PossibilitiesCards/Possibilities.interface'

import events from '@/utils/events'

import Type from './Type'

const PossibilitiesCards: FC<PossibilitiesProps> = ({
  type = 0,
  className = ''
}) => {
  const [animationStart, setAnimationStart] = useState(false)

  useEffect(() => {
    events.on('waypoint::Possibilities::enter', () => setAnimationStart(true))
    events.on('waypoint::Possibilities::leave', () => setAnimationStart(false))

    return () => {
      events.off('waypoint::Possibilities::enter', () =>
        setAnimationStart(true)
      )
      events.off('waypoint::Possibilities::leave', () =>
        setAnimationStart(false)
      )
    }
  }, [])

  return (
    <div className={clsx({ 'animation-ready': animationStart }, className)}>
      <Waypoint
        onEnter={() => events.emit('waypoint::Possibilities::enter')}
        onLeave={() => events.emit('waypoint::Possibilities::leave')}
      >
        <div>
          <Type key={Math.random()} id={type} />
        </div>
      </Waypoint>
    </div>
  )
}
export default PossibilitiesCards
