import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import Heading from '@/components/Heading'

import useMediaQuery from '@/hooks/useMediaQuery'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Benefits.module.scss'

interface BenefitsCardProps extends DefaultComponentProps {
  id: string
  image?: string | null
  heading?: string | null
  summary?: string | null
  index?: number
  withAnimation?: boolean
}

const BenefitsCard: FC<BenefitsCardProps> = ({
  id,
  withAnimation = true,
  className = '',
  heading = null,
  summary = null,
  index = 0
}) => {
  const isMiddleScreen = useMediaQuery('(min-width: 960px)')

  const {
    i18n: { language }
  } = useTranslation()

  return (
    <div
      className={clsx(
        styles['benefits-card'],
        className,
        styles[`benefits-card_${id}`],
        { [styles['benefits-card_with-animation']]: withAnimation }
      )}
      style={{
        backgroundImage: `url(/images/benefits-card/${language}/${id}/${
          isMiddleScreen ? 'l' : 's'
        }.svg)` as string,
        transitionDelay: `${index * 0.3}s`
      }}
    >
      {/*<Icon
        className={styles['benefits-card__img']}
        name={`benefits-card/${image}/${isMiddleScreen ? 'l' : 's'}`}
      />*/}
      <Heading level={2} className={styles['benefits-card__heading']}>
        {heading}
      </Heading>
      {summary && (
        <div
          className={styles['benefits-card__summary']}
          dangerouslySetInnerHTML={{ __html: summary }}
        />
      )}
    </div>
  )
}
export default BenefitsCard
