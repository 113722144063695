import clsx from 'clsx'
import { FC } from 'react'

import Button from '@/components/Button'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './RoundedTabs.module.scss'

interface RoundedTabProps {
  id: string | number
  name: string
  active?: boolean
}

interface RoundedTabsProps extends DefaultComponentProps {
  items: RoundedTabProps[]
  onClick: Function
}

const RoundedTabs: FC<RoundedTabsProps> = ({
  className = '',
  items = [],
  onClick = () => {}
}) => {
  return (
    <nav className={clsx(styles['tabs'], className)}>
      <ul className={styles['tabs__list']}>
        {items.map(({ id, name, active }) => (
          <li key={id} className={styles['tabs__item']}>
            <Button
              variant={'unstyled'}
              type={'button'}
              title={name}
              className={clsx(styles['tabs__link'], {
                [styles['tabs__link_active']]: active
              })}
              onClick={() => onClick(id)}
            >
              {name}
            </Button>
          </li>
        ))}
      </ul>
    </nav>
  )
}
export default RoundedTabs
