import clsx from 'clsx'
import { FC, useCallback, useRef } from 'react'

import Button from '@/components/Button'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Tabs.module.scss'

interface TabProps {
  id: string | number
  name: string
  active?: boolean
}

interface TabsProps extends DefaultComponentProps {
  items: TabProps[]
  onClick: Function
}

const Tabs: FC<TabsProps> = ({
  className = '',
  items = [],
  onClick = () => {}
}) => {
  const tabRef = useRef<any>()
  const itemsRef = useRef<any[]>([])

  const handleClick = useCallback(
    (id: number) => {
      onClick(id)
      tabRef.current.scrollTo({
        left: itemsRef.current[id].offsetLeft,
        behavior: 'smooth'
      })
    },
    [onClick, tabRef, itemsRef]
  )

  return (
    <nav className={clsx(styles['tabs'], className)} ref={tabRef}>
      <ul className={styles['tabs__list']}>
        {items.map(({ id, name, active }) => (
          <li
            key={id}
            className={styles['tabs__item']}
            ref={el => (itemsRef.current[id as number] = el)}
          >
            <Button
              title={name}
              variant={'unstyled'}
              type="button"
              className={clsx(styles['tabs__link'], {
                [styles['tabs__link_active']]: active
              })}
              onClick={e => handleClick(id as number)}
            >
              <span dangerouslySetInnerHTML={{ __html: name }} />
            </Button>
          </li>
        ))}
      </ul>
    </nav>
  )
}
export default Tabs
