import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useIntersection } from 'react-use'

import Cards from '@/components/Cards'
import Heading from '@/components/Heading'
import RoundedTabs from '@/components/RoundedTabs'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'
import { useMenu } from '@/bus/menu'

export const Methods: FC<DefaultComponentProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const TABS = useMemo(() => t('methods:tabs', { returnObjects: true }), [t])
  const [activeId, setActiveId] = useState(Object.keys(TABS)[0])
  const { setData } = useMenu()
  const handleClick = (id: string) => {
    setActiveId(id)
  }

  const CARDS = useMemo(() => t(`methods:cards`, { returnObjects: true }), [t])

  const intersectionRef = useRef(null)

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '-116px',
    threshold: 0.1
  })

  useEffect(() => {
    setData({ interSection: intersection?.isIntersecting ? 'methods' : null })
    // eslint-disable-next-line
  }, [intersection?.isIntersecting])

  return (
    <section
      id={'methods'}
      ref={intersectionRef}
      className={clsx(styles['section'], className)}
    >
      <div className={styles['section__inner']}>
        <div className={styles['section__content']}>
          <Heading className={styles['section__heading']} level={2}>
            {t('methods:heading')}
          </Heading>
          <div
            className={styles['section__summary']}
            dangerouslySetInnerHTML={{ __html: t('methods:summary') }}
          />

          <RoundedTabs
            className={styles['section__tabs']}
            items={Object.entries(TABS).map(([k, v]) => ({
              id: k,
              active: k === activeId,
              name: v
            }))}
            onClick={handleClick}
          />

          {Object.entries(CARDS).map(([k, v]) => (
            <div
              key={k}
              className={clsx(styles['section__tab'], {
                [styles['section__tab_active']]: k === activeId
              })}
            >
              <Cards
                items={v}
                className={styles['section__payment-methods']}
                name={k}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
